import { IPlatformConfig, EnvType } from 'lib/src/types';

export const config: IPlatformConfig = {
  name: 'pca6',
  indexTemplatePath: '/src/platforms/_index.tpl.html',
  theme: 'orange',
  platform: {
    [EnvType.local]: {
      api: {
        hosts: [
          // 'http://localhost:8882',
          'https://pca6.pccctesting.com',
        ],
      },
    },
    [EnvType.dev]: {
      api: {
        hosts: ['https://pca6.pccctesting.com'],
      },
    },
    [EnvType.staging]: {
      api: {
        hosts: [],
      },
    },
    [EnvType.prod]: {
      api: {
        hosts: [],
      },
    }
  }
};
